export const environment = {
  production: true,
  conversationHistoryUrl: 'https://api.community.inator.cloud/bot/session/conversation',
  aiAdviceUrl: 'https://api.community.inator.cloud/agent/chat',
  aiAdviceAutocompleteUrl: 'https://api.community.inator.cloud/agent/autocomplete',
  // TODO: Use cloudfront URL
  // apiUrl: 'https://engage.community.inator.cloud/api/adm',
  apiUrl: 'https://4vhpkhiodl.execute-api.eu-west-1.amazonaws.com/prod/api/adm',
  apiKey: 'FgZzXMYoF02fP9b4Vce598vQA7URseyC2Ax6fkpx',
  googleMapApiKey: 'AIzaSyAD7v9KMq7NiT_6dP7AvPPVMFFtd5wL4FE',
  cacheApi: ['/cannedresponses'],
  autoRefreshDashboard: false,

  amplifyConfig: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:7c6cfcd0-bd2d-4692-a972-a5b51f1563cb',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_Hed2syTBA',
    aws_user_pools_web_client_id: '64qa7p4qh8cdb9aeg29tagko5j',
    oauth: {},
    aws_appsync_graphqlEndpoint: 'https://4lerwfefb5gtdfoprodiscp3mq.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    Storage: {
      AWSS3: {
        // TODO: Add functionality to use another domain name
        bucket: 'asset.engage.community.inator.cloud',
        region: 'eu-west-1'
      }
    }
  },

  internalChatConfig: {
    appsync: {
      url: 'https://aa5cgsttmra3vk2kqsiem3fb44.appsync-api.eu-west-1.amazonaws.com/graphql',
      region: 'eu-west-1'
    },
    s3: {
      bucket: 'asset.engage.community.inator.cloud',
      region: 'eu-west-1'
    },
    production: true
  }
};
